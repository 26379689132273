<template>
  <div class="app-container">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Maintain" name="Maintain">
        <MaintainTab/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import MaintainTab from "@/views/region-lb/maintainTab.vue";

export default {
  name: "event",
  components: {
    MaintainTab,
  },
  data() {
    return {
      activeTab: "Maintain"
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
