<template>
  <div v-loading="this.isLoading">
    <el-form ref="form" label-width="300px">
      <MyFormItem label="Leaderboard Index" tooltip="Chọn region id cần show leaderboard">
        <el-input-number v-model="showLeaderBoard.regionId" :step="1" size="small" :min="1"/>
      </MyFormItem>

      <MyFormItem label="UserStatus" tooltip="UserStatus">
        <el-select v-model="showLeaderBoard.status">
          <el-option v-for="data in this.userStatusShow" :key="data.value" :label="data.label" :value="data.value"/>
        </el-select>
      </MyFormItem>

      <el-collapse class="space-to-bottom" :accordion="false" v-if="showLeaderBoard.result.length > 0">
        <el-collapse-item title="View Leaderboard Data">
          <el-table :data="showLeaderBoard.result" border>
            <el-table-column
                prop="Rank"
                label="Rank"/>
            <el-table-column
                prop="UserId"
                label="UserId"/>
            <el-table-column
                prop="Score"
                label="Score"/>
          </el-table>
        </el-collapse-item>
      </el-collapse>

      <el-button type="primary" @click="onTapGetLeaderBoard()">
        Get Leaderboard
      </el-button>

      <br/>
      <br/>

      <MyFormItem label="UserId" tooltip="UserId">
        <el-input class="form-input" v-model="showUserScore.userId" size="normal" clearable/>
      </MyFormItem>

      <el-collapse class="space-to-bottom" :accordion="false" v-if="showUserScore.result.length > 0">
        <el-collapse-item title="View all UserScore Data">
          <el-table :data="showUserScore.result" border>
            <el-table-column
                prop="UserId"
                label="UserId"/>
            <el-table-column
                prop="RegionId"
                label="RegionId"/>
            <el-table-column
                prop="Score"
                label="Score"/>
            <el-table-column
                prop="Status"
                label="Status"/>
          </el-table>
        </el-collapse-item>
      </el-collapse>

      <el-button type="primary" @click="onTapGetUserScore()">
        Get all UserScore
      </el-button>

      <br/>
      <br/>

      <MyFormItem label="LoginId" tooltip="">
        <el-input class="form-input" v-model="loginData.loginId" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="UserId" tooltip="">
        <el-input class="form-input" v-model="loginData.userId" size="normal" readonly/>
      </MyFormItem>

      <MyFormItem label="Token" tooltip="">
        <el-input class="form-input" v-model="loginData.token" size="normal" readonly/>
      </MyFormItem>

      <el-button type="primary" @click="onTapGetToken()">
        Get token
      </el-button>

      <br/>
      <br/>

      <MyFormItem label="Leaderboard Index" tooltip="">
        <el-input-number v-model="addUserScore.regionId" :step="1" size="small" :min="1"/>
      </MyFormItem>

<!--      <MyFormItem label="UserId" tooltip="UserId">-->
<!--        <el-input class="form-input" v-model="addUserScore.userId" size="normal" clearable/>-->
<!--      </MyFormItem>-->

      <MyFormItem label="Score" tooltip="">
        <el-input-number v-model="addUserScore.score" :step="1" size="small" :min="0"/>
      </MyFormItem>

      <el-collapse class="space-to-bottom" :accordion="false" v-if="addUserScore.result.length > 0">
        <el-collapse-item title="View all UserScore Data">
          <el-table :data="addUserScore.result" border>
            <el-table-column
                prop="UserId"
                label="UserId"/>
            <el-table-column
                prop="RegionId"
                label="RegionId"/>
            <el-table-column
                prop="Score"
                label="Score"/>
          </el-table>
        </el-collapse-item>
      </el-collapse>

      <el-button type="primary" @click="onTapAddUserScore()">
        Add Score
      </el-button>

<!--      <br/>-->
<!--      <br/>-->

<!--      <MyFormItem label="UserId" tooltip="UserId">-->
<!--        <el-input class="form-input" v-model="changeUserStatus.userId" size="normal" clearable/>-->
<!--      </MyFormItem>-->

<!--      <MyFormItem label="UserStatus" tooltip="UserStatus">-->
<!--        <el-select v-model="changeUserStatus.userStatus">-->
<!--          <el-option v-for="data in this.userStatusShow" :key="data.value" :label="data.label" :value="data.value"/>-->
<!--        </el-select>-->
<!--      </MyFormItem>-->

<!--      <el-table :data="changeUserStatus.result" border v-if="changeUserStatus.result.length > 0">-->
<!--        <el-table-column-->
<!--            prop="Message"-->
<!--            label="Message"/>-->
<!--      </el-table>-->

<!--      <el-button type="primary" @click="onTapChangeUserStatus()">-->
<!--        Change User Status-->
<!--      </el-button>-->

    </el-form>
  </div>
</template>

<script>

import MyFormItem from "@/views/loop-machine/component/myFormItem.vue";
import request from "@/utils/request-service-base";
import {Message} from "element-ui";

function noop(typeLb) {}

export default {
  components:{
    MyFormItem
  },
  props: {
    data: {},
    onChange: {
      type: Function,
      default: noop
    },
  },
  data: () => {
    return {
      updateMsg: {
        msg: "",
      },
      isLoading: false,
      loginData: {
        loginId: "abc",
        token: "",
        userId: "",
      },
      showLeaderBoard: {
        regionId: 1,
        status: "Normal",
        result: [],
      },
      showUserScore: {
        userId: "abc",
        result: [],
      },
      addUserScore: {
        userId: "abc",
        regionId: 1,
        score: 0,
        result: [],
      },
      changeUserStatus: {
        userId: "xyz",
        userStatus: "Normal",
        result: [],
      },
      userStatusShow: [
        {
          value: "Cheater",
          label: "Cheater",
        },
        {
          value: "Normal",
          label: "Normal",
        }
      ],
    }
  },
  computed: {

  },
  methods: {
    async onTapGetLeaderBoard() {
      this.isLoading = true;
      this.showLeaderBoard.result = [];

      try {

        let responseFull = await request({
          url: `/leaderboard/region/v2/get-leaderboard-by-status/${this.showLeaderBoard.regionId}/${this.showLeaderBoard.status}`,
          method: `get`,
        });

        let response = responseFull.data;

        for (let i = 0; i < response.length; i++) {
          this.showLeaderBoard.result.push({
            Rank: i + 1,
            UserId: response[i].UserId,
            Score: response[i].Score,
          });
        }
      }catch (ex) {
        Message({
          message: `Get leaderboard error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.isLoading = false;
    },

    async onTapGetUserScore() {
      this.isLoading = true;
      this.showUserScore.result = [];

      try {

        let responseFull = await request({
          url: `/maintain/leaderboard/region/get-all-user-score/${this.showUserScore.userId}`,
          method: `get`,
        });

        let response = responseFull.data;

        for (let i = 0; i < response.length; i++) {
          this.showUserScore.result.push({
            RegionId: response[i].Region,
            UserId: response[i].UserId,
            Score: response[i].Score,
            Status: response[i].Status,
          });
        }
      }catch (ex) {
        Message({
          message: `Get leaderboard error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.isLoading = false;
    },

    // async onTapChangeUserStatus() {
    //   this.isLoading = true;
    //   this.changeUserStatus.result = [];
    //
    //   try {
    //
    //     let responseFull = await request({
    //       url: `/maintain/leaderboard/region/change-user-status`,
    //       method: `post`,
    //       data: {
    //         UserId: this.changeUserStatus.userId,
    //         Status: this.changeUserStatus.userStatus,
    //       },
    //     });
    //
    //     let response = responseFull.data;
    //
    //     this.changeUserStatus.result.push({
    //       Message: response.message,
    //     });
    //   }catch (ex) {
    //     this.changeUserStatus.result.push({
    //       Message: ex,
    //     });
    //   }
    //
    //   this.isLoading = false;
    // },

    async onTapAddUserScore() {
      this.isLoading = true;
      this.addUserScore.result = [];

      try {

        let responseFull = await request({
          url: `/leaderboard/region/v2/add-score`,
          method: `post`,
          headers: {
            "Authorization" : `Bearer ${this.loginData.token}`
          },
          data: {
            RegionId: this.addUserScore.regionId,
            Score: this.addUserScore.score,
          },
        });

        let response = responseFull.data;

        this.addUserScore.result.push({
          RegionId: response.Region,
          UserId: response.UserId,
          Score: response.Score,
        });

      }catch (ex) {
        this.addUserScore.result.push({
          Message: ex,
        });
      }

      this.isLoading = false;
    },

    async onTapGetToken() {
      this.isLoading = true;
      try {
        let responseFull = await request({
          url: `/login`,
          method: `post`,
          data: {
            LoginId: this.loginData.loginId,
          },
        });

        let response = responseFull.data;

        this.loginData.token = response.Token;
        this.loginData.userId = response.UserId;
      }
      catch (ex) {
        Message({
          message: `Get token error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.isLoading = false;
    }
  }
};
</script>